import React, {useEffect} from 'react'
import { Box, IconButton, TextField, Typography , Button} from '@mui/material'
import logo from '../../assets/Img/logo/footer_logo.svg'
import { Link } from 'react-router-dom'
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import Aos from "aos";
import Newsletter from './Newsletter';

const Footer = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <Box
        data-aos="fade-up"
        sx={{
          bgcolor: "#D76A25",
          height: "250px",
          display: "grid",
          placeItems: "center",
          width:'100%'
        }}
      >
        <Box
       
          sx={{
            width: {md:"50%", xs:'90%'},
            margin: "0 auto",
            bgcolor: "#fff",
            p: 2,
            mt: -8,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection:{md:'row', xs:'column'},
            boxSizing:'border-box',
            rowGap:2,
            columnGap:2
          }}
        >
          <Typography sx={{fontSize:'14px', fontFamily:'itim'}}>Subscribe to my newsletter</Typography>
          {/* <TextField sx={{width:{md:'67%', xs:'90%'}}} size='small'/> */}
          <Newsletter/>
          {/* <Button variant="contained" sx={{fontFamily:'itim'}}>Subscribe</Button> */}
        </Box>
        <Box
          sx={{
            margin: "0 auto",
            width: "85%",
            display: "flex",
            flexDirection:{md:'row', xs:'column'},
            alignItems: {md:"center", xs:'left'},
            justifyContent: "space-between",
          }}
        >
          <Link to="">
            <img src={logo} width={200} />
          </Link>

          <Box sx={{ display: "flex", columnGap: 3 }}>
            <IconButton>
              <FaFacebookF style={{ color: "#fff", fontSize: "16px" }} />
            </IconButton>
            <IconButton>
              <FaXTwitter style={{ color: "#fff", fontSize: "16px" }} />
            </IconButton>
            <IconButton>
              <FaInstagram style={{ color: "#fff", fontSize: "16px" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Footer