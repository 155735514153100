import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Navbar from "./Navbar";
import banner2 from "../../assets/Img/lemon.png";

const Banner3 = ({ img }) => {
  return (
    <>
      <Box
        sx={{
          height: { lg: "600px", md: "500px", sm: "400px", xs: "400px" },
          // backgroundColor: "#0E0E0E",
          backgroundImage: `url('${banner2}')`,
          backgroundSize: {md:'cover', xs:"600px",},
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          // backgroundPositionY: "150px",
          pt: 10,
        }}
      >
        <Navbar />
      </Box>
    </>
  );
};

export default Banner3;
