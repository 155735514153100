import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import Navbar from "./Navbar";
import banner2 from "../../assets/Img/M3 bnw.jpg";

import back from "../../assets/Img/but.svg";

const Banner2 = ({ img }) => {
  return (
    <>
      <Box
        sx={{
          // height: {  md: "100vh", sm: "400px", xs: "400px" },
          backgroundColor: "",
          // pt: 10,
          backgroundImage: `url('${back}')`,
          backgroundSize: "contain",
          backgroundPositionY:'349px',
          backgroundRepeat:'no-repeat'
        }}
      >
        <Navbar />
        <Box sx={{ display: "flex", alignItems: "center",  }}>
          <Grid container spacing={0} sx={{display:'flex', flexDirection:{md:'row', xs:'column-reverse'}}}>
            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  height: { md: "100vh", sm: "500px", xs: "auto" },

                  width: "100%",
                  // bgcolor: "#fff",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Box sx={{ width: {md:"70%", xs:'90%'}, mt:{md:-10, xs:5} }}>
                  <Typography
                    sx={{ fontFamily: "montserrat", fontSize: "14px" }}
                  >
                    Meet your transformative Life Coach
                  </Typography>
                  <Typography sx={{ fontFamily: "itim", fontSize: "40px" }}>
                    i am <span style={{ color: "#009872" }}>Jacky </span>
                    <span style={{ color: "#D76A25" }}>Jasper</span>
                  </Typography>
                  <Typography sx={{ fontSize:'14px', lineHeight:'25px', mt: 2 }}>
                  Jacquelyne Uchechi is a seasoned  transformative coach with over a decade of experience working with hurting women and young people. She is passionate about helping young women turn their pain into positive strength. Driven by  the philosophy that the family is the unit that moulds and shapes functional individuals and societies.
                  </Typography>
                  <Box sx={{mt:2, height:'1px', bgcolor:'#000', width:'50%'}}/>
                  <Box sx={{mt:1, height:'1px', bgcolor:'#000', width:'70%'}}/>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  height: { md: "100vh", sm: "500px", xs: "500px" },
mt:{md:0, xs:5},
                  width: "100%",
                  background: `url('${banner2}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Banner2;
