import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Img/logo/lemlogo.svg";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import Drawers from "./Drawers";

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <AppBar sx={{ background: "#fff", boxShadow: "none" }}>
        <Box
          sx={{
            margin: "0 auto",
            width: "85%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 3,
          }}
        >
          <Link to="/">
            <img src={logo} width={200} />
          </Link>

          {isMobile ? (
            <Drawers />
          ) : (
            <>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Tabs>
                  <Link to="/">
                    <Tab
                      label="Home"
                      sx={{
                        fontFamily: "itim",
                        color: "#000",
                        fontSize: "16px",
                      }}
                    />
                  </Link>
                  <Link to="/about">
                    <Tab
                      label="About"
                      sx={{
                        fontFamily: "itim",
                        color: "#000",
                        fontSize: "16px",
                      }}
                    />
                  </Link>
                  <Link to="/lemonade">
                    <Tab
                      label="Lemonade Podcast"
                      sx={{
                        fontFamily: "itim",
                        color: "#000",
                        fontSize: "16px",
                      }}
                    />
                  </Link>
                  <Link to="/afro-kinky">
                    <Tab
                      label="Afro Kinky"
                      sx={{
                        fontFamily: "itim",
                        color: "#000",
                        fontSize: "16px",
                      }}
                    />
                  </Link>
                  <a
                    href="https://thelemonadecoach.medium.com/"
                    target="_blank"
                  >
                    <Tab
                      label="Blog"
                      sx={{
                        fontFamily: "itim",
                        color: "#000",
                        fontSize: "16px",
                      }}
                    />
                  </a>
                </Tabs>
                <Stack direction="row" sx={{ ml: 5 }}>
                  <a href="">
                    <IconButton>
                      <FaFacebookF style={{ fontSize: "14px" }} />
                    </IconButton>
                  </a>
                  <a href="">
                    <IconButton>
                      <FaXTwitter style={{ fontSize: "14px" }} />
                    </IconButton>
                  </a>
                  <a href="">
                    <IconButton>
                      <FaInstagram style={{ fontSize: "14px" }} />
                    </IconButton>
                  </a>
                </Stack>
              </Box>
            </>
          )}
        </Box>
      </AppBar>
    </>
  );
};

export default Navbar;
