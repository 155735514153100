import {
  Button,
  Box,
  Typography,
  IconButton,
  InputLabel,
  TextField,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";

// import Mailchimp from 'mailchimp-api-v3';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from "./CustomForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 500, md: 500, sm: 350, xs: 350 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Newsletter = (props) => {
  const postUrl = `https://gmail.us21.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

// console.log(postUrl)


  return (
    <>

                <MailchimpSubscribe
                  url={postUrl}
                  render={({ subscribe, status, message }) => (
                    <CustomForm
             
                      status={status}
                      message={message}
                      onValidated={(formData) => subscribe(formData)}
                    />
                  )}
                />
              </>
  

  );
};

export default Newsletter;
