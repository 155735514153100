import React, { useState, useEffect } from "react";
import Navbar from "../Components/Common/Navbar";
import {
  Box,
  Button,
  Grid,
  Typography,
  Modal,
  TextField,
  IconButton,
} from "@mui/material";
import book from "../assets/Img/book.png";
import mockup from "../assets/Img/mobile.png";
import { PaystackButton } from "react-paystack";
import pdfFile from "../assets/pdf.pdf";
import Aos from "aos";
import "aos/dist/aos.css";
import Video from "../Components/Common/Video";
import gal1 from "../assets/Img/Gallery/gal1.jpg";
import gal2 from "../assets/Img/Gallery/gal2.jpg";
import gal3 from "../assets/Img/Gallery/gal3.jpg";
import gal4 from "../assets/Img/Gallery/gal4.jpg";
import gal5 from "../assets/Img/Gallery/gal5.jpg";
import gal6 from "../assets/Img/Gallery/gal6.jpg";
import gal7 from "../assets/Img/Gallery/gal7.jpg";
import gal8 from "../assets/Img/Gallery/gal8.jpg";
import gal9 from "../assets/Img/Gallery/gal9.jpg";
import gal10 from "../assets/Img/Gallery/gal10.jpg";

import gal11 from "../assets/Img/Gallery/gal11.jpg";
import gal12 from "../assets/Img/Gallery/gal12.jpg";

import { CloseOutlined } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 400, xs: 300 },
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 500, xs: 300 },
  bgcolor: "#000",

  boxShadow: 24,
  p: 0,
};
const Afro = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [image, setImage] = useState(null);
  const [email, setEmail] = useState("");

  const downloadPDF = () => {
    fetch(pdfFile)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "MY AFRO KINKY JOURNAL FINAL (watermark).pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error("Error downloading the PDF file:", error);
      });
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: 5000 * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
 
  };

  const handlePaystackSuccessAction = (reference) => {
    downloadPDF();
    setOpen(false);

    console.log(reference);
  };
  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const componentProps = {
    ...config,
    text: "Donate Here",
    className: "paystack-button",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          height: "600px",
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(52,89,133,1) 35%, rgba(0,0,0,1) 100%)",
        }}
      >
        <Box
          sx={{
            width: { md: "85%", xs: "95%" },
            overflow: "hidden",
            margin: "0 auto",
            mt: 15,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 700,
                fontSize: "56px",
                lineHeight: "60px",
              }}
            >
              {" "}
              My <br />
              Afro-Kinky
              <br /> Hair.{" "}
            </Typography>
            <Typography sx={{ color: "#fff" }}>
              ...the story of identity
            </Typography>
          </Box>
          <img src={book} width={600} />
        </Box>
      </Box>
      <Box sx={{ height: "10px", bgcolor: "#ddd" }} />
      <Box sx={{ height: "10px", bgcolor: "#ece9e9" }} />
      <Box sx={{ height: "10px", bgcolor: "#f7f5f5" }} />
      <Box
        sx={{
          width: { md: "85%", xs: "95%" },
          margin: "0 auto",
          p: { md: 3, xs: 1 },
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ mt: 5 }}>
          <Grid
            container
            sx={{ display: "flex", flexDirection: { md: "row", xs: "column" } }}
          >
            <Grid item md={12} xs={12}>
              <Box sx={{ mr: { md: 4, xs: 0 } }}>
                <Typography
                  sx={{ fontWeight: 700, fontSize: "12px", color: "#345985" }}
                >
                  BY JACQUELYNE UCHECHI JASPER - IKPENDU
                </Typography>
                <Typography
                  sx={{
                    mt: 5,
                    fontWeight: 900,
                    fontSize: { md: "40px", xs: "30px" },
                  }}
                >
                  Celebrating African Identity through Afro-Kinky Hair
                </Typography>
                <Typography data-aos="fade-up" sx={{ mt: 3, fontSize: "12px" }}>
                  I am an Afro-kinky hair enthusiast and I have worn it
                  consistently for six years. I curate beautiful African stories
                  through my hair.
                  <br /> <br />
                  In my quest to promote the African culture and build
                  confidence in every woman who wishes to express herself
                  through her African identity, I have documented my hair
                  journey through this journal.
                  <br />
                  <br />
                  This journal is an interesting read that will assist you in
                  honing the requisite skills to groom healthy hair. This is not
                  in any way to bash other forms of hair; however, this is to
                  celebrate and promote our African heritage.
                  <br />
                  <br />
                  My hair is one of my tools to encourage young African women to
                  own their identity, be comfortable, and evolve. <br />
                  <br />
                  <span style={{ fontStyle: "italic", fontSize: "12px" }}>
                    On Friday 4, August 2023, at the Envoy Hotel Abuja, there
                    was a public launch of my first published book, My
                    Afro-Kinky Hair…..the Story of Identity. It was a beautiful
                    evening well attended by media, industry experts, civil
                    society, philanthropists, natural hair enthusiasts, and the
                    public.
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} xs={12} sx={{}}>
              {/* <QuiltedImageList /> */}
              <Box sx={{ mt: 5 }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  Take a look at how we spent the evening
                </Typography>
              </Box>
              <Box
                className="no_scroll"
                sx={{
                  mt: 5,
                  display: "flex",
                  columnGap: 3,
                  width: "100%",
                  overflowX: "scroll",
                }}
              >
                <Box>
                  <Box
                    onClick={() => {
                      setOpen2(true);
                      setImage(gal1);
                    }}
                    sx={{
                      height: "200px",
                      width: "250px",
                      boxSizing: "border-box",
                      background: `url('${gal1}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      transition: "0.3s all ease",
                      "&:hover": {
                        border: "5px solid #333",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    onClick={() => {
                      setOpen2(true);
                      setImage(gal2);
                    }}
                    sx={{
                      height: "200px",
                      width: "250px",
                      boxSizing: "border-box",
                      background: `url('${gal2}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      transition: "0.3s all ease",
                      "&:hover": {
                        border: "5px solid #333",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    onClick={() => {
                      setOpen2(true);
                      setImage(gal3);
                    }}
                    sx={{
                      height: "200px",
                      width: "250px",
                      boxSizing: "border-box",
                      background: `url('${gal3}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      transition: "0.3s all ease",
                      "&:hover": {
                        border: "5px solid #333",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    onClick={() => {
                      setOpen2(true);
                      setImage(gal4);
                    }}
                    sx={{
                      height: "200px",
                      width: "250px",
                      boxSizing: "border-box",
                      background: `url('${gal4}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      transition: "0.3s all ease",
                      "&:hover": {
                        border: "5px solid #333",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    onClick={() => {
                      setOpen2(true);
                      setImage(gal5);
                    }}
                    sx={{
                      height: "200px",
                      width: "250px",
                      boxSizing: "border-box",
                      background: `url('${gal5}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      transition: "0.3s all ease",
                      "&:hover": {
                        border: "5px solid #333",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    onClick={() => {
                      setOpen2(true);
                      setImage(gal6);
                    }}
                    sx={{
                      height: "200px",
                      width: "250px",
                      boxSizing: "border-box",
                      background: `url('${gal6}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      transition: "0.3s all ease",
                      "&:hover": {
                        border: "5px solid #333",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    onClick={() => {
                      setOpen2(true);
                      setImage(gal7);
                    }}
                    sx={{
                      height: "200px",
                      width: "250px",
                      boxSizing: "border-box",
                      background: `url('${gal7}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      transition: "0.3s all ease",
                      "&:hover": {
                        border: "5px solid #333",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    onClick={() => {
                      setOpen2(true);
                      setImage(gal8);
                    }}
                    sx={{
                      height: "200px",
                      width: "250px",
                      boxSizing: "border-box",
                      background: `url('${gal8}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      transition: "0.3s all ease",
                      "&:hover": {
                        border: "5px solid #333",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    onClick={() => {
                      setOpen2(true);
                      setImage(gal9);
                    }}
                    sx={{
                      height: "200px",
                      width: "250px",
                      boxSizing: "border-box",
                      background: `url('${gal9}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      transition: "0.3s all ease",
                      "&:hover": {
                        border: "5px solid #333",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    onClick={() => {
                      setOpen2(true);
                      setImage(gal10);
                    }}
                    sx={{
                      height: "200px",
                      width: "250px",
                      boxSizing: "border-box",
                      background: `url('${gal10}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      transition: "0.3s all ease",
                      "&:hover": {
                        border: "5px solid #333",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    onClick={() => {
                      setOpen2(true);
                      setImage(gal11);
                    }}
                    sx={{
                      height: "200px",
                      width: "250px",
                      boxSizing: "border-box",
                      background: `url('${gal11}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      transition: "0.3s all ease",
                      "&:hover": {
                        border: "5px solid #333",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    onClick={() => {
                      setOpen2(true);
                      setImage(gal12);
                    }}
                    sx={{
                      height: "200px",
                      width: "250px",
                      boxSizing: "border-box",
                      background: `url('${gal12}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      transition: "0.3s all ease",
                      "&:hover": {
                        border: "5px solid #333",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Video />
      <Box sx={{ margin: "0 auto", width: { md: "75%", xs: "90%" }, mt: 5 }}>
        <Typography>Media Links</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <li data-aos="fade-up">
            <a href="https://www.pulse.ng/lifestyle/fashion/afro-hair-enthusiast-urges-women-to-embrace-natural-african-hair-identity/xye5mtf.amp">
              <Typography
                sx={{
                  color: "#333",
                  fontWeight: 700,
                  textDecoration: "underline",
                  fontSize: { md: "14px", xs: "12px" },
                }}
              >
                https://www.pulse.ng/lifestyle...
              </Typography>
            </a>
          </li>
          <li data-aos="fade-up">
            <a href="https://www.newsauthority.com.ng/afro-hair-enthusiast-urges-women-to-embrace-natural-african-hair-identity/">
              <Typography
                sx={{
                  color: "#333",
                  fontWeight: 700,
                  textDecoration: "underline",
                  fontSize: { md: "14px", xs: "12px" },
                }}
              >
                https://www.newsauthority.com...
              </Typography>
            </a>
          </li>

          <li data-aos="fade-up">
            <a href="https://punchng.com/embrace-african-natural-hair-expert-urges-women/">
              <Typography
                sx={{
                  color: "#333",
                  fontWeight: 700,
                  textDecoration: "underline",
                  fontSize: { md: "14px", xs: "12px" },
                }}
              >
                https://punchng.com/emb...
              </Typography>
            </a>
          </li>
          <li data-aos="fade-up">
            <a href="https://www.hammertimes.com.ng/afro-hair-enthusiast-urges-women-to-embrace-natural-african-hair-identity/">
              <Typography
                sx={{
                  color: "#333",
                  fontWeight: 700,
                  textDecoration: "underline",
                  fontSize: { md: "14px", xs: "12px" },
                }}
              >
                https://www.hammertime.com.ng/afro...
              </Typography>
            </a>
          </li>
        </Box>
      </Box>
      <Box
        className="review"
        sx={{ height: { md: "400px", xs: "auto" }, mt: 6 }}
      >
        <Typography
          sx={{
            fontWeight: "bolder",
            textAlign: "center",
            color: "#fff",
            pt: 5,
            fontSize: "16px",
          }}
        >
          Reviews
        </Typography>
        <Box
          sx={{
            margin: "0 auto",
            width: "70%",
            height: { md: "300px", xs: "auto" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <div class="card" data-aos="fade-right">
            <a
              class="card1"
              href=""
              style={{ height: "200px", overflow: "scroll" }}
            >
              <p>Tolu Ajiboye</p>
              <p class="small" style={{ fontSize: "12px" }}>
                Being a “natural hair girlie” myself , It was such a delight for
                me to come in contact with this unique, unconventional , yet
                truly inspiring book. My own “natural hair girlie” started about
                six years ago, it was birthed from seeing older women around me
                with damaged hair and hair loss which I believe was caused by
                lots of chemical and unhealthy processes . I decided that I
                would go another route by being more deliberate about grooming
                my hair naturally and hopefully when I’m older, my own hair
                story will be different. I must say I commend Jacquelyne’s hair
                journey and how she has beautifully curated it in this book.
                Finally I will like to conclude this book by quoting a portion
                of the book “The African hair I one universal topic that has
                vast interpretations across tribes, religion, and individuals.
                Largely, hair is viewed as a symbol of strength and holiness.
                This has also played a significant role in our society, it’s
                been views as a symbol of youthfulness and beauty in women and
                of strength in men. A healthy hair denotes an overall wellbeing
                of your health. Amazingly , a good hair enhances beauty , boosts
                confidence and Identity
              </p>
              <div class="go-corner" href="#">
                <div class="go-arrow">→</div>
              </div>
            </a>
          </div>
          <div class="card" data-aos="fade-down">
            <a class="card1" href="">
              <p>Chimezie Asiegbu</p>
              <p class="small" style={{ fontSize: "12px" }}>
                My Afro-Kinky Hair: What a master piece story of identity
                expressed through natural experience. Am highly impressed by
                your story. Truly Aham - efula.
              </p>
              <div class="go-corner" href="#">
                <div class="go-arrow">→</div>
              </div>
            </a>
          </div>
          {/* <div class="card" >
            <a class="card1" href="">
              <p>Amarachi Precious Eleazu</p>
              <p class="small">
                {" "}
                It is the perfect guide to overcoming societal prejudice and
                gaining self acceptance! No, it’s not just ‘Afro hair’, it’s an
                extension of our very being– our identity!’
              </p>
              <div class="go-corner" href="#">
                <div class="go-arrow">→</div>
              </div>
            </a>
          </div> */}
        </Box>
      </Box>
      <Box sx={{ width: { md: "55%", xs: "90%" }, margin: "0 auto", my: 10 }}>
        <Box
          data-aos="fade-right"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",

            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Box sx={{ width: "100%" }} data-aos="fade-up">
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "30px",
                mt: 4,
                textAlign: "center",
              }}
            >
              Next Steps <br />
              One Million Afro Girls Book Tour.
              <br />
            </Typography>
            <Box
              align="center"
              sx={{
                mt: { md: -25, xs: -20 },
                width: "100%",
                ml: { md: 0, xs: -15 },
              }}
            >
              <img src={mockup} width={700} />
            </Box>
            <Typography sx={{ fontSize: "14px", textAlign: "center" }}>
              I'm excited to share that the hair journal will birth a project
              for the next couple of years. This legacy book holds immense value
              and will be shared in select places across diverse communities.
              <br />
              <br /> As part of the initiative, we plan to conduct a book tour,
              visiting secondary schools in Nigeria and various peer group
              communities worldwide, providing limited free copies of this
              journal.
              <br />
              <br />
              Would you like to be a part of this meaningful endeavour? You can
              support someone on their journey of self-identity by donating a
              copy of this book to them, for a minimum token of N5,000 ($5).
              <br />
              Let's come together to embrace and celebrate the beauty of
              self-identity, courage and confidence. Your participation will
              undoubtedly make a difference.
            </Typography>

            <Button
              data-aos="zoom-in"
              fullWidth
              variant="contained"
              onClick={handleOpen}
              sx={{
                borderRadius: "100px",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "bolder",
                py: 2,
                borderColor: "#345985",
                mt: 4,
              }}
            >
              Donate Here N5,000 ($5)
            </Button>
          </Box>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TextField
            fullWidth
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <PaystackButton disabled {...componentProps} />
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <Box align="right">
            <IconButton
              sx={{ bgcolor: "#fff", my: 2, mr: 2 }}
              onClick={handleClose2}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
          <Box sx={{ bgcolor: "#333" }}>
            <Box
              sx={{
                height: "400px",
                width: "100%",
                boxSizing: "border-box",
                background: `url('${image}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                cursor: "pointer",
                transition: "0.3s all ease",
              }}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Afro;
