

import React from 'react';
import ReactPlayer from 'react-player';
import vid1 from '../../assets/Img/vid1.MP4'
import vid2 from '../../assets/Img/vid2.MP4'
import { Box } from '@mui/material';

const Video = () => {
  return (
    <>
    <Box sx={{display:'flex', flexDirection:{md:'row', xs:'column'}, justifyContent:'space-between',alignItems:'center', margin:'0 auto', width:{md:'75%', xs:'95%'}, mt:14}}>
        <Box sx={{bgcolor:'#000', display:'grid', placeItems:'center', height:'300px'}}>
        <ReactPlayer
        url={vid1}
        controls
        width="50%"
        height="300px"
       
      />
        </Box>
  
      <Box sx={{bgcolor:'#000', display:'grid', placeItems:'center', height:'300px'}}>
      <ReactPlayer
        url={vid2}
        controls
        width="50%"
       height="300px"
      />
      </Box>
  
</Box>
  </>
  )
}

export default Video