import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  InputLabel,
  TextField,
  Typography,
  LinearProgress,
} from "@mui/material";

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    setSuccess(true);

    setEmail("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
  };
  console.log(status, message, "=>Sammy");

  useEffect(()=>{
if(success){
  setTimeout(()=>{
setSuccess(false)
  },3000)
}
  }, [success])

  return (
    <>
      {success ? (
        <Typography variant="h3" sx={{ fontSize: "20px", fontWeight:700 }}>
          {message}
        </Typography>
      ) : (
        <>
          {/* <Box>
            {success && <p>{message}</p>}
            {status === "sending" && <Loader/>}
          </Box> */}

          <TextField
            placeholder="Enter your email"
            // fullWidth
            sx={{ width: { md: "67%", xs: "90%" } }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              style: {
                //   fontFamily: "Gordita",

                fontSize: "13px",
                borderRadius: "10px",
                offset: " 1px solid #737373",
                // Replace with your desired font family
              },
            }}
          />

          <Box align="center">
            {status === "sending"  ? (
              <Button
                disabled
                variant="contained"
                sx={{ borderRadius: "20px", py: 1 }}
                fullWidth
                formValues={[email]}
              >
                Subscribing...
              </Button>
            ):(
                  <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{ borderRadius: "20px" }}
                fullWidth
                formValues={[email]}
              >
                Subscribe
              </Button>
            )
            
            
            }
    
          </Box>
        </>
      )}
    </>
  );
};

export default CustomForm;
