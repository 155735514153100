import React,{useEffect, useState} from 'react'
import Navbar from '../Components/Common/Navbar'
import { Box } from '@mui/material';
import axios from 'axios';

const Blog = () => {
  const [posts, setPosts] = useState([]);



  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://api.medium.com/v1/me/posts', {
          headers: {
            'Authorization': '2c080d70d6d61a8529b46ae727657a2eb91886bed59d2891bbaf49c914b4913bd', // Replace with your access token
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });

        setPosts(response.data.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);
  return (
<>
<Navbar/>

<Box>

</Box>
</>
  )
}

export default Blog


// 