import logo from './logo.svg';
import './App.css';
import { Routes } from './Routes';
import { theme } from './theme';
import { ThemeProvider } from '@mui/material';
import Footer from './Components/Common/Footer';
import ScrollToTop from './ScrollToTop';
import Fab from '@mui/material/Fab';

import { SiWhatsapp } from "react-icons/si";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes />
        <Footer/>
        <ScrollToTop/>
        <Fab
          color="primary"
          sx={{
            position: 'fixed',
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
          }}
        >
          <a href="https://chat.whatsapp.com/Coyn8roaJfUCzVsGJkmhne" style={{color:'#fff'}}>
         <SiWhatsapp style={{fontSize:'20px'}} /></a>
        </Fab>
      </ThemeProvider>

    </div>
  );
}

export default App;
