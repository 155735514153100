import { useRoutes } from "react-router-dom";
import Main from "./Pages/Main";
import About from "./Pages/About";
import Lemonade from "./Pages/Lemonade";
import Afro from "./Pages/Afro";
import Blog from "./Pages/Blog";

export function Routes () {
  let element = useRoutes([
    {
element:<Main/>,
path:'/'
    },
    {
      path:'/about',
      element:<About/>
    },
    {
      path:'/lemonade',
      element:<Lemonade/>
    },
    {
path:'/afro-kinky',
element:<Afro/>
    },
    {
      path:'/blog',
      element:<Blog/>
    }
  ])
  return element
}