import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#009872",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    button: {
      textTransform: "initial",
    },
  },
});