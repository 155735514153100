import { Avatar, Box, Grid, Typography, Button } from "@mui/material";
import React from "react";
import Banner3 from "../Components/Common/Banner3";
import img1 from "../assets/Img/lemoade/1.png";
import img2 from "../assets/Img/lemoade/2.png";
import img3 from "../assets/Img/lemoade/3.png";
import img4 from "../assets/Img/lemoade/4.jpeg";

import logo from "../assets/Img/logo2.svg";
import button1 from "../assets/Img/youtube.svg";
import button2 from "../assets/Img/spotify.svg";
import backLogo from "../assets/Img/image (4).svg";

const Lemonade = () => {
  return (
    <>
      <Banner3 />
      <Box
        sx={{
          margin: "0 auto",
          width: { md: "85%", xs: "95%" },
          mt: 5,
          pb: 10,
        }}
      >
        <Box
          sx={{
            margin: "0 auto",
            width: { md: "60%", xs: "100%" },
            mt: { md: 0, xs: -15 },
          }}
        >
          <Typography sx={{ textAlign: "center", fontSize: "14px" }}>
            The lemonade podcast is a podcast designed to inspire and support
            the emotionally hurting young and contemporary african woman with a
            focus to help them unburden without been judged and explore new ways
            to turn their pains into positive strengths.
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#BDFA57",
            my: 4,
            backgroundImage: `url('${backLogo}')`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: { md: "1000px", xs: "right" },
            backgroundPositionY: { md: "initial", xs: "bottom" },
            backgroundSize: { md: "initial", xs: "50px" },
            py: 3,
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ margin: "0 auto", width: "85%", mt: 7, pb: 7 }}>
            <img src={logo} width={300} />

            <Box sx={{ mt: 3 }}>
              <a
                href="https://www.youtube.com/@thelemonadepodcastwithjacky?si=FGY_V0iT1bOsDJDf"
                target="_blank"
              >
                <Button>
                  <img src={button1} width={120} />
                </Button>
              </a>
              <a
                href="https://open.spotify.com/show/3ZS9MQu5VZDXpUw9oz4m7f?si=qbIqyYteRKe4rFt4JNkwRw"
                target="_blank"
              >
                <Button>
                  <img src={button2} width={150} />
                </Button>
              </a>
            </Box>
          </Box>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ margin: "0 auto", width: { md: "90%", xs: "100%" }, mt: 5 }}
        >
          <Grid item md={3} xs={6}>
            <Avatar
              src={img1}
              variant="square"
              sx={{ width: "100%", height: { xs: "200px", md: "400px" } }}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <Avatar
              src={img2}
              variant="square"
              sx={{ width: "100%", height: { xs: "200px", md: "400px" } }}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <Avatar
              src={img3}
              variant="square"
              sx={{ width: "100%", height: { xs: "200px", md: "400px" } }}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <Avatar
              src={img4}
              variant="square"
              sx={{ width: "100%", height: { xs: "200px", md: "400px" } }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Lemonade;
