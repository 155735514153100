import React from 'react'
import Banner2 from '../Components/Common/Banner2'
import { Box,  Typography, Grid } from '@mui/material'
import Gallery from '../Components/Common/Gallery';
import img2 from '../assets/Img/abt2.png';
import img3 from '../assets/Img/0X8A9309 copy 2.png'
import Footer from '../Components/Common/Footer';

const About = () => {
  return (
    <>
      <Banner2 />
      <Box sx={{ mt: {  md: 15, sm: 15, xs: 8 } }}>
        <Box
          align="center"
          sx={{ margin: "0 auto", width: {md:"85%",xs:'95%'}, mt: 5, pb: 10 }}
        >
          <Typography sx={{ textAlign: "center", color: "#0E0E0E",  }}>
          Professional Background
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: { lg: "30px", md: "30px", sm: "25px", xs: "20px" },
              fontWeight: 700,
              color: "#0E0E0E",
              mt: 2,
              borderBottom: "1px solid #0E0E0E",
              width: "fit-content",
              pb: 3,
            }}
          >
            An award-winning life coach &<br /> transformative agent 👋
          </Typography>
          <Box
            sx={{
          
              margin: "0 auto",
              width: { lg: "70%", md: "70%", sm: "90%", xs: "100%" },
              columnGap: 8,
              mt: {  md: 5, sm: 5, xs: 2 },
            }}
          >
           
          
          </Box>
          <Box sx={{ margin: "0 auto", width: {md:"80%", xs:'95%'}, mt: 3 }}>
            <Typography
              sx={{
                fontSize: { lg: "14px", md: "14px", sm: "14px", xs: "14px" },
                textAlign: "justify",
                // fontFamily:'itim'
              }}
            >
             Jackie  thrives to shape perspectives and mindsets through her podcast series (the lemonade podcast with jackie), one on one coaching, newsletters and other speaking channels.
Known for her favorite quote; when life throws lemons at you, turn them into lemonade. She has been in the lemonade business of helping women  turn their lemons into beautiful lemonades The lemonade podcast with Jacky seeks to provide support through the help of other professionals to coach and nurture hurting women through a healing process were they become self aware and whole.<br/><br/>Jacky penned down her first published book in a journal titled : My Afro kinky hair ……………. The story of identity. A premier indigenous journal on grooming  a healthy African hair. She is an Afro natural  hair enthusiast  and has worn her natural hair over the last seven years.<br/><br/>
Jacquelyne Uchechi is also an accomplished program management administrator with over a decade of experience in handling donor funded programmes. A two time scholar of the orange knowledge management programme and a fellow of the Netherland fellow program by the Dutch Government.<br/><br/>As a lady who wears many shoes, Jacky is  a licensed minister  who alongside her spouse minister to young couples both locally and internationally through The carpenters workshop.
            </Typography>
          </Box>


          {/* <Box align="left" sx={{ mt: 4, width: "100%" }}>
            <Typography sx={{ fontSize: "50px" }}>
              <strong>Gallery</strong>
            </Typography>
            <Typography sx={{fontWeight:700}}>
              Jacky: Advocate, Consultant, Mentor, Author, and Minister - A
              Journey of Impact and Empowerment
            </Typography>
            <Gallery />
          </Box> */}
        </Box>

        <Box sx={{margin:'0 auto', width:{md:'85%', xs:'95%'}}}>

          <Grid container>
            <Grid item md={6} xs={12}>
              <Box
              sx={{background:`url('${img3}')`, height:{md:'600px',xs:'400px'}, backgroundSize:'cover'}}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Box sx={{height:{md:'600px', xs:'400px'}, display:'grid', placeItems:'center'}}>
                <Box sx={{width:{md:'70%', xs:'95%'}}}>
                  <Typography sx={{color:'#D76A25', mb:2, fontSize:'30px', fontWeight:700}}>Passion for Empowerment and Mentorship</Typography>
                  <Typography sx={{fontSize:'14px', lineHeight:'25px'}}>Beyond her professional accomplishments, Jacky is a dedicated mentor and advocate for empowering young women. She has mentored over 30 young girls and actively promotes African culture and values, particularly the beauty and significance of African hair. Her passion led her to author the premier indigenous practical journal on managing and grooming African hair titled "My Afro-Kinky Hair...the story of identity."</Typography>
                </Box>

              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{margin:'0 auto', width:{md:'85%', xs:'95%'}}}>

          <Grid container>
          <Grid item md={6} xs={12}>
              <Box sx={{height:{md:'600px', xs:'450px'}, display:'grid', placeItems:'center'}}>
                <Box sx={{width:{md:'70%', xs:'95%'}}}>
                  <Typography sx={{color:'#009872', mb:2, fontSize:'30px', fontWeight:700}}>Ministry and Family</Typography>
                  <Typography sx={{fontSize:'14px', lineHeight:'25px'}}>In addition to her career, Jacquelyne is a licensed minister who, alongside her spouse, shares platforms both locally and internationally through "The Carpenters Workshop." This platform seeks to support young couples in navigating the challenges that arise at the start of their marital journey. She also serves as an Associate Director at House of Gems International School in Nigeria.</Typography>
                </Box>

              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box
              sx={{background:`url('${img2}')`, height:{md:'600px', xs:'400px'}, backgroundSize:'cover'}}
              />
            </Grid>
           
          </Grid>
        </Box>
      </Box>
      {/* <Footer/> */}
    </>
  );
}

export default About